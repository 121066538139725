*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type: none;
}

:root {
    --primary: #6a59ff;
    --white: #ffffff;
    --bg: #f5f5f5;
}

html {
    /* font-size: 62.5%; */
    scroll-behavior: smooth;
}

@media (min-width: 1440px) {
    html {
        zoom: 1.5;
    }
}

@media (min-width: 2560px) {
    html {
        zoom: 1.7;
    }
}

@media (min-width: 3860px) {
    html {
        zoom: 2.5;
    }
}

::-webkit-scrollbar {
    width: 0.8rem;
}

::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background: rgb(88, 139, 202);
    transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(88, 139, 202);
}

::-webkit-scrollbar-track {
    background: #f9f9f9;
}

.container {
    max-width: 115rem;
    margin: 0 auto;

}

@media (max-width: 500px) {
    .container {
        max-width: 33rem;
        margin: 0 auto;
    }
}

@media (max-width: 700px) {
    .container {
        max-width: 33rem;
        margin: 0 auto;

    }
}


.swiper_container {
    height: 22rem;
    padding: 2rem 0;
    position: relative;
}

.swiper-slide {
    width: 22rem;
    height: 22rem;
    position: relative;
}

@media (max-width: 500px) {
    .swiper_container {
        height: 22rem;
    }

    .swiper-slide {
        width: 22rem !important;
        height: 22rem !important;
    }

    .swiper-slide img {
        width: 22rem !important;
        height: 22rem !important;
    }

    .swiper-slide img:first-child {
        width: 75% !important;
        height: 17rem !important;
        object-fit: fill;
        border-radius: 2rem;
    }

    .my_cover {
        object-fit: cover !important;
    }
}

@media (max-width: 700px) {
    .swiper_container {
        height: 25rem;
    }

    .swiper-slide {
        width: 20rem !important;
        height: 22rem !important;
    }

    .swiper-slide img {
        width: 22rem !important;
        height: 20rem !important;
    }

}

.swiper-slide img {
    width: 20rem;
    height: 18rem;
    padding: 10px;
    border-radius: 2rem;
    object-fit: fill;
    /* border: 3px solid rgb(88, 139, 202); */
    /* box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2); */
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
    display: none;
}

.slider-controler {
    position: relative;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-controler .swiper-button-next {
    left: 58% !important;
    transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
    .slider-controler .swiper-button-next {
        left: 70% !important;
        transform: translateX(-70%) !important;
    }
}

@media (max-width: 450px) {
    .slider-controler .swiper-button-next {
        left: 80% !important;
        transform: translateX(-80%) !important;
    }
}

@media (max-width: 990px) {
    .slider-controler .swiper-button-prev {
        left: 30% !important;
        transform: translateX(-30%) !important;
    }
}

@media (max-width: 450px) {
    .slider-controler .swiper-button-prev {
        left: 20% !important;
        transform: translateX(-20%) !important;
    }
}

.slider-controler .slider-arrow {
    background: var(--white);
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    left: 42%;
    transform: translateX(-42%);
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
    font-size: 2rem;
    color: #222224;
}

.slider-controler .slider-arrow::after {
    content: '';
}

.swiper-pagination {
    position: relative;
    width: 15rem !important;
    bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
    background: var(--primary);
}


.swiper-3d .swiper-wrapper {
    transform-style: preserve-3d;
    transform: translate3d(-390px, 0px, 0px);
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset, 10px);
    left: auto;
    font-size: 20px !important;
    color: #253441 !important;
    background-color: #f7c42b !important;
    padding: 15px !important;
    height: 100px !important;
    width: 20px !important;
}