.overCard {
        position: absolute;
                top: 0;
                left: 19px;
                border-radius: 12px;
                width: 250px;
                height: 100%;
                background-color: rgba(59, 59, 59, 0.65);
                cursor: pointer;
                display: none;
                transition: 1.5s;
                color: #FFF;
                font-weight: bold;
                align-items: center;
                justify-content: center;

                
}
.box {
        transition: 1.5s;
}
.box:hover .overCard {
        display: flex;
        transition: 1.5s;
}