.overCardServ {
    position: absolute;
    top: 0;
    left: 0px;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    background-color: rgba(63, 38, 38, 0.65);
    cursor: pointer;
    display: none;
    transition: 1.5s;
    color: #FFF;
    font-weight: bold;
    align-items: center;
    justify-content: center;


}

.box {
    transition: 1.5s;
}

.box:hover .overCardServ {
    display: flex;
    transition: 1.5s;
}