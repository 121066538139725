.header{
    background-image: url('./../../assets/header/header.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header_ChannelLetters {
    background-image: url('./../../assets/Channel Letters/007.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header_Interfaces {
    background-image: url('./../../assets/Interfaces/001.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 700px) {
.header,
.header_ChannelLetters,
.header_Interfaces {
        height: 50vh;
    }
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(59, 59, 59, 0.65);
}

